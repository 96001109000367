import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import {useTranslation} from "react-i18next";
import {isAuthenticated} from "../../plugins/AuthService";
import Typography from "@material-ui/core/Typography";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SimCardIcon from '@material-ui/icons/SimCard';
import MoneyIcon from '@material-ui/icons/Money';
import AssessmentIcon from '@material-ui/icons/Assessment';
import BusinessIcon from '@material-ui/icons/Business';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import CreateIcon from '@material-ui/icons/Create';
import {Link} from "react-router-dom";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24,
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolBarUser: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'left',
        paddingLeft: 16,
        alignItems: 'center',
        color: theme.palette.primary.main
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuButton: {
        marginRight: 8,
        marginLeft: 36,
        [theme.breakpoints.down("sm")]: {
            marginRight: -5
        }
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginTop: 65
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    drawerList: {
        '& a': {
            textDecoration: 'inherit',
            color: 'inherit'
        }
    },
    drawerListItem: {
        borderBottom: '1px solid #EEE'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

export default function AdminDrawer() {
    const {t} = useTranslation()
    const classes = useStyles();

    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const currentUser = isAuthenticated();

    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
        >
            <div className={classes.toolbarIcon}>
                <Typography variant="subtitle1" component="h5" className={classes.toolBarUser}>
                    <VerifiedUserIcon
                        color={"secondary"}
                        style={{marginRight: 8}}/> {decodeURIComponent(escape(currentUser.nam))}
                </Typography>
                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon/>
                </IconButton>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                >
                    <MenuIcon/>
                </IconButton>
            </div>
            <Divider/>

            <List className={classes.drawerList}>
                <Link to={'/admin'}>
                    <ListItem button className={classes.drawerListItem}>
                        <ListItemIcon>
                            <MoneyIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('stats')}/>
                    </ListItem>
                </Link>
                <Link to={'/admin/users'}>
                    <ListItem button className={classes.drawerListItem}>
                        <ListItemIcon>
                            <PeopleAltIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('users')}/>
                    </ListItem>
                </Link>
                 <Link to={'/admin/rooms'}>
                    <ListItem button className={classes.drawerListItem}>
                        <ListItemIcon>
                           
                            <BusinessIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('rooms')}/>
                    </ListItem>
                </Link>
                <Link to={'/admin/imeis'}>
                    <ListItem button className={classes.drawerListItem}>
                        <ListItemIcon>
                            <SimCardIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('imeis')}/>
                    </ListItem>
                </Link>
                <Link to={'/admin/stats'}>
                    <ListItem button className={classes.drawerListItem}>
                        <ListItemIcon>
                            <AssessmentIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('stats_period')}/>
                    </ListItem>
                </Link>
                <Link to={'/admin/data'}>
                    <ListItem button className={classes.drawerListItem}>
                        <ListItemIcon>
                           <ImportContactsIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('history')}/>
                    </ListItem>
                </Link>
                    <Link to={'/admin/datam'}>
                    <ListItem button className={classes.drawerListItem}>
                        <ListItemIcon>
                          <CreateIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('data_enter')}/>
                    </ListItem>
                </Link>

            </List>

            <Divider/>
        </Drawer>
    );
}
