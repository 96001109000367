const imgAPI = {
  avatar: [
    '/static/images/avatars/pp_girl.svg',
    '/static/images/avatars/pp_girl2.svg',
    '/static/images/avatars/pp_girl3.svg',
    '/static/images/avatars/pp_girl4.svg',
    '/static/images/avatars/pp_girl5.svg',
    '/static/images/avatars/pp_girl.svg',
    '/static/images/avatars/pp_boy.svg',
    '/static/images/avatars/pp_boy2.svg',
    '/static/images/avatars/pp_boy3.svg',
    '/static/images/avatars/pp_boy4.svg',
    '/static/images/avatars/pp_boy5.svg'
  ],
  photo: [
    'https://via.placeholder.com/675x900/e1ad92/fff',
    'https://via.placeholder.com/967x725/ea6d6d/fff',
    'https://via.placeholder.com/1280x849/ea6db7/fff',
    'https://via.placeholder.com/967x725/bb6dea/fff',
    'https://via.placeholder.com/1048x701/6d6fea/fff',
    'https://via.placeholder.com/1050x700/6dc0ea/fff',
    'https://via.placeholder.com/970x725/6deaa6/fff',
    'https://via.placeholder.com/1051x700/b8de27/fff',
    'https://via.placeholder.com/1051x701/de9f27/fff',
    'https://via.placeholder.com/1050x700/ef4545/fff',
    'https://via.placeholder.com/1050x700/ffc4c4/757575',
    'https://via.placeholder.com/640x447/fdffc4/757575',
    'https://via.placeholder.com/1280x851/c4ffd7/757575',
    'https://via.placeholder.com/640x425/c4cdff/757575',
  ],
  profile: [
    './images/cabinets/v001.jpg',
    './images/cabinets/v002.jpg',
    './images/cabinets/v003.jpg',
    './images/cabinets/h001.png',
    './images/cabinets/h002.png',
    './images/cabinets/h003.png',
    './images/cabinets/h004.png',
    './images/cabinets/h005.png',
    './images/cabinets/h006.png',
    './images/cabinets/h007.png',
    './images/cabinets/h008.png',
    './images/cabinets/h009.png',
  ]
};

export default imgAPI;
