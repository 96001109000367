import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import {useTranslation} from "react-i18next";
import axios from "axios";
import 'react-notifications/lib/notifications.css';
import { NotificationManager} from 'react-notifications';

//import Checkbox from "@material-ui/core/Checkbox";
//import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function AddRoomDialog(props) {

    const {t} = useTranslation();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [newRoom, setNewRoom] = useState({
        name: '',
        address: '',
        description: ''
    });

   const onChange = ({target: {name, value}}) => {
        console.log(name, value)
       // value = name === 'is_manager' ? !!value : value;
        setNewRoom({...newRoom, [name]: value})
    };
    

     /* const handleManager = (event) => {
        onChange(({target: {name: event.target.name, value: event.target.checked}}));
    };  */

    const onSubmit = (event) => {
        if (event) {
            event.preventDefault();
        } 

        console.log('submitted')

        axios.post('/api/v1/rooms', newRoom)
            .then(response => {
                if(response.data.room.ID){
                    NotificationManager.success(response.data.room.ID, t('add_room'), 3000);
                    setTimeout(()=>{
                        props.onAddRoomSuccess();
                        handleClose();
                    },1000)
                }

            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
            })
    };

    return (
        <div style={{padding: '0px 10px', textAlign: 'right'}}>
            <Button
                variant="outlined"
                color="secondary"
                startIcon={<LibraryAddIcon/>}
                onClick={handleClickOpen}
            >
                {t('add_room')}
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={"sm"}>
                <form onSubmit={onSubmit}>
                    <DialogTitle id="form-dialog-title">{t('add_room')}</DialogTitle>
                    <DialogContent>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t('title')}
                            name="name"
                            autoComplete="new-room-name"
                            autoFocus
                            value={newRoom.name}
                            onInput={onChange}
                        />
                         <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t('address')}
                            name="address"
                            autoComplete="address"
                            value={newRoom.address}
                            onInput={onChange}
                        />
                      <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t('description')}
                            name="description"
                            autoComplete="description"
                            value={newRoom.description}
                            onInput={onChange}
                        />
                     
                        
                    </DialogContent>
                    <DialogActions style={{justifyContent: 'space-between'}}>
                        <Button onClick={handleClose} variant={"outlined"} type={'reset'}>
                            {t('cancel')}
                        </Button>
                        <Button variant={"contained"} color="secondary" type={'submit'}>
                            {t('to_add')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
