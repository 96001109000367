import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
// import {useText} from '~/theme/common';
import useStyles from './cards-style';
import {makeStyles} from "@material-ui/core/styles";

const useText = makeStyles(theme => ({
    title: {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 48,
        lineHeight: '72px',
        [theme.breakpoints.down('md')]: {
            fontSize: 38,
            lineHeight: '60px'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 28,
            lineHeight: '44px'
        },
    },
    title2: {
        fontSize: 36,
        lineHeight: '56px',
        fontWeight: theme.typography.fontWeightBold,
        [theme.breakpoints.down('md')]: {
            fontSize: 32,
            lineHeight: '48px'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 24,
            lineHeight: '36px',
        }
    },
    subtitle: {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: 28,
        lineHeight: '44px',
        [theme.breakpoints.down('md')]: {
            fontSize: 24,
            lineHeight: '36px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
            lineHeight: '28px',
        },
    },
    subtitle2: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: 22,
        lineHeight: '32px',
        [theme.breakpoints.down('md')]: {
            fontSize: 20,
            lineHeight: '32px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
            lineHeight: '24px',
        },
    },
    paragraph: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: 16,
        lineHeight: '24px'
    },
    caption: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: 16,
        lineHeight: '24px',
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
            lineHeight: '22px',
        },
    }
}));

export default function ImageThumb(props) {
    const classes = useStyles();
    const text = useText();
    const {
        img,
        title,
        link,
        size,
    } = props;

    const setSize = sizePaper => {
        switch (sizePaper) {
            case 'short':
                return classes.short;
            case 'long':
                return classes.long;
            default:
                return classes.medium;
        }
    };
    return (
        <Paper className={clsx(classes.imgThumb, setSize(size))}>
            <div className={classes.figure}>
                <div className={classes.img} style={{backgroundImage: `url(${img})`}}/>
            </div>
            <div className={classes.detail}>
                <Typography variant="h6" className={text.subtitle}>{title}</Typography>
                <Link href="#">{link}</Link>
            </div>
        </Paper>
    );
}

ImageThumb.propTypes = {
    img: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
};
