import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from "react-i18next";
import axios from "axios";
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import Typography from "@material-ui/core/Typography";
import CounterDatePicker from "../common/Counters/DatePicker";
import moment from "moment";
import Compress from "react-image-file-resizer";



export default function MachineDataDialog(props) {

    const state = {
        file: null,
        base64URL: ""
    };


    const [selectedDateStart, setSelectedDateStart] = useState(new Date());
    const handleDateChangeStart = (date) => {
        console.log('handleDateChangeStart date: ', date)
        setSelectedDateStart(date)
        setNewIndication({ ...newIndication, date: moment(date).unix() })
    };
    const [State, setState] = useState({});


    const [newIndication, setNewIndication] = useState({ imei_id: props.machinesData.machine.ID, type: "change_settings", date: moment(selectedDateStart).unix() });
    /*  useEffect(() => {
         if (!newIndication.imei_id) {
             setNewIndication({ ...newIndication, imei_id: props.machinesData.machine.ID })
             setNewIndication({ ...newIndication, type: "end_workshit" })
             console.log("seEffect ", newIndication);
         }
     });
  */
    /*useState(() => {
        if (!newIndication.imei_id) {
            setNewIndication({ ...newIndication, imei_id: props.machinesData.machine.ID })
            setNewIndication({ ...newIndication, type: "end_workshit" })
            console.log("Effect ", newIndication);
        }
    });*/

    const { t } = useTranslation();
    // eslint-disable-next-line
    const [selectedMachinesData, setselectedMachinesData] = useState(null);
    // const [machinesData, setMachinesData] = useState({});

    const [openMachineDataDialog, setOpenMachineDataDialog] = React.useState(false);

    const handleClose = () => {
        props.closeDialog()
    };


    const [fieldError, setFieldError] = useState({ coin_in: false, key_in: false, key_out: false });
    const onChange = ({ target: { name, value } }) => {
        // value = name === 'is_manager' ? !!value : value;
        if (value.match('^[0-9]*$')) {
            setNewIndication({ ...newIndication, [name]: parseInt(value, 10) })
            setFieldError({ ...fieldError, [name]: false })
        } else {
            setFieldError({ ...fieldError, [name]: true })
        }
    };
    const onChangeDesc = ({ target: { name, value } }) => {


        setNewIndication({ ...newIndication, [name]: value })

    };



    const onSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
        // setNewIndication({ ...newIndication, imei_id: props.machinesData.machine.ID })
        console.log("onSubmit: ", newIndication);
        console.log("f", props.machinesData);
        //  setNewMachine({ ...newMachine, "imei_id": props.machinesData[0].machines.ID })
        //  console.log("fgf", props.machinesData[0].machine.ID, " ", newMachine);
        //imei_id":1,"type":"end_workship","photo":"'$photo'","coin_in":100,"key_in":101,"key_out":102
        axios.post('/api/v1/indication', newIndication)
            .then(response => {
                //end_workshit change_setting

                if (response.data.status) {
                    NotificationManager.success(response.data.status, t('data_entered'), 3000);
                    setTimeout(() => {
                        handleClose();
                    }, 1000)
                }
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error(error.response.data.error || error.response.data, 'Oops!', 3000);
            })
    };





    const handleCloseMachineDataDialog = () => {
        setOpenMachineDataDialog(false);
        props.closeDialog();

        //setselectedMachinesData(null);
        //fetchRoomsData();
    };





    const onFileResize = (e) => {
        let file = e.target.files[0];
        Compress.imageFileResizer(
            file, // the file from input
            480, // width
            480, // height
            "JPEG", // compress format WEBP, JPEG, PNG
            70, // quality
            0, // rotation
            (uri) => {
                console.log("uri", uri);
                setNewIndication({ ...newIndication, photo: uri })
                // You upload logic goes here
            },
            "base64" // blob or base64 default base64
        );
    }



    return (

        <div style={{ padding: '0px 10px', textAlign: 'right' }}>
            <Dialog open={props.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={"sm"}>
                <form onSubmit={onSubmit}>
                    <DialogTitle id="form-dialog-title">{t('data_enter')}</DialogTitle>
                    <DialogContent>

                        <Grid container spacing={0}>
                            <Grid item xs={12} md={6} lg={6}>
                                <Typography variant={"h6"} style={{ margin: '12px auto' }}>
                                    {t('data')}: &nbsp;
                                    <CounterDatePicker setNewDate={handleDateChangeStart} />
                                </Typography>
                            </Grid>


                        </Grid>

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            error={fieldError.coin_in}
                            label={t('coin_in')}
                            name="coin_in"
                            autoComplete="new-room-name"
                            autoFocus
                            // value={newIndication.coin_in}
                            onChange={onChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            error={fieldError.key_in}
                            name="key_in"
                            label={t('key_in')}
                            autoComplete="new-room-address"
                            //value={newIndication.key_in}
                            onInput={onChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            error={fieldError.key_out}
                            name="key_out"
                            label={t('key_out')}
                            autoComplete="new-room-description"
                            // value={newIndication.key_out}
                            onInput={onChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth

                            name="description"
                            label={t('description')}
                            autoComplete="new-room-description"
                            // value={newIndication.key_out}
                            onInput={onChangeDesc}
                        />


                        <input type="file" style={{ margin: '22px auto' }} name="file" accept=".jpg, .jpeg, .png" onChange={onFileResize} />



                    </DialogContent>

                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={handleClose} variant={"outlined"} type={'reset'}>
                            {t('cancel')}
                        </Button>


                        <Button variant={"contained"} color="secondary" type={'submit'} disabled={fieldError.coin_in || fieldError.key_in || fieldError.key_out}>
                            {t('enter')}
                        </Button>

                    </DialogActions>
                </form>


            </Dialog>


            {
                selectedMachinesData
                    ? <MachineDataDialog
                        isOpen={openMachineDataDialog}
                        closeDialog={handleCloseMachineDataDialog}
                        roomData={selectedMachinesData} />
                    : <span />
            }


        </div>

    );
}




