import React, { useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslation} from "react-i18next";
import axios from "axios";
import 'react-notifications/lib/notifications.css';
import { NotificationManager} from 'react-notifications';

export default function EditUserDialog(props) {

    const {t} = useTranslation();

    const handleClose = () => {
        props.closeDialog()
    };

    const [newUser, setNewUser] = useState({...{password:''},...props.userData});

    const onChange = ({target: {name, value}}) => {
        console.log(props.userData)

        console.log(name, value)
        value = name === 'is_manager' ? !!value : value;
        setNewUser({...newUser, [name]: value})
    };

    const onSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        axios.put('/api/v1/users/' + props.userData.id, newUser)
            .then(response => {
                if(response.data.id){
                    NotificationManager.success(response.data.id, t('edit_user'), 3000);
                    setTimeout(()=>{
                        handleClose();
                    },1000)
                }
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error(error.response.data.error || error.response.data, 'Oops!', 3000);
            })
    };

    return (
        <div style={{padding: '0px 10px', textAlign: 'right'}}>
            <Dialog open={props.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={"sm"}>
                <form onSubmit={onSubmit}>
                    <DialogTitle id="form-dialog-title">{t('edit_user')}</DialogTitle>
                    <DialogContent>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t('name')}
                            name="name"
                            autoComplete="new-user-name"
                            autoFocus
                            value={newUser.name}
                            onInput={onChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t('password')}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={newUser.password}
                            onInput={onChange}
                        />
                    </DialogContent>
                    <DialogActions style={{justifyContent: 'space-between'}}>
                        <Button onClick={handleClose} variant={"outlined"} type={'reset'}>
                            {t('cancel')}
                        </Button>
                        <Button variant={"contained"} color="secondary" type={'submit'}>
                            {t('to_edit')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
