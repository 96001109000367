/* Actions */
export const setCustomer = (customerInfo) => ({
    type: 'SET_CUSTOMER_INFO',
    customerInfo
});

/* Reducers */

const guest = {
    "adm": false,
    "operator": false,
    "aud": "fish-guests",
    "exp": null,
    "jti": "",
    "nam": "Guest",
    "nbf": null,
    "sub": null
}
export const fetchCustomerInfo = (state = guest, action) => {
    switch (action.type) {
        case 'SET_CUSTOMER_INFO':
            const customer = action.customerInfo ? action.customerInfo : guest;
            localStorage.setItem('customer', JSON.stringify(customer));
            return customer;
        default:
            return state
    }
};
