import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import AdminLayout from "./index";
import * as AuthService from "../../plugins/AuthService";


const AdminLayoutRoutes = ({component: Component, ...rest}) => {

    return (
        <Route {...rest} render={props =>

            AuthService.isAuthenticated() && AuthService.isAuthenticated().adm
                ?
                (
                    <AdminLayout>
                        <Component {...props}/>
                    </AdminLayout>
                )
                : (
                    <Redirect
                        to={{
                            pathname: "/denied",
                            state: {from: props.location}
                        }}
                    />
                )


        }/>
    )
}

export default AdminLayoutRoutes
