import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from "react-i18next";
import 'react-notifications/lib/notifications.css';
import theme from "../../../theme";
import Counters from "./Counters";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Moment from "react-moment";



export default function CounterInfoDialog(props) {

    const { t } = useTranslation();

    const handleClose = () => {
        props.closeDialog()
    };

    const [counterData] = useState(props.counterData);



    return (

        <div style={{ padding: '0px 10px', textAlign: 'right' }}>
            <Dialog open={props.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={"lg"}>
                <DialogTitle id="form-dialog-title"
                    style={{
                        background: theme.palette.secondary.main,
                        color: 'white',
                        textAlign: 'left'
                    }}>
                    <small>{t('stats')}: { }

                        <Moment format="DD/MM/YYYY HH:mm:ss" style={{ fontSize: 15 }}>
                            {counterData.at_start}
                        </Moment>

                        { } - { }

                        <Moment format="DD/MM/YYYY HH:mm:ss" style={{ fontSize: 15 }}>
                            {counterData.at_end}
                        </Moment>


                        {/*  {counterData.at_start}-{counterData.at_end} </small> {counterData.serial} */}
                    </small> </DialogTitle>

                <Divider />
                <DialogContent style={{ textAlign: 'center' }}>
                    <Container maxWidth="lg" style={{ minHeight: 420 }}>
                        <Counters countersData={counterData} />
                    </Container>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button onClick={handleClose} variant={"contained"} type={'reset'} fullWidth color={'secondary'} size={'large'}>
                        {t('ok')}
                    </Button>

                </DialogActions>
            </Dialog>
        </div>


    );


}
