import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import axios from "axios";
import Moment from "react-moment";
import Skeleton from "@material-ui/lab/Skeleton";
import MaterialTable from "material-table";
import tableLocalizations from '../tableLocalizations.json'
//import CounterInfoDialog from "./CounterInfoDialog";
import { NotificationManager } from "react-notifications";


const useStyles = makeStyles((theme) => ({
    depositContext: {
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        textAlign: 'center'
    },
    fixedHeight: {
        height: 180,
    },
    fixedHeightSkeleton: {
        height: 180,
        justifyContent: 'center',
        alignItems: 'center'
    },
}));

export default function CountersList(props) {
    const { t, i18n } = useTranslation()
    const classes = useStyles();
    const fixedHeightSkeleton = clsx(classes.paper, classes.fixedHeightSkeleton);
    const [machinesData, setMachinesData] = useState([])
    const [countersDataGot, setCountersDataGot] = useState(false)








    const fetchMachinesData = () => {

        return new Promise((resolve, reject) => {
            axios.get('/api/v1/machines')
                .then(response => {
                    return response.data.machines || [];
                })
                .then(result => {
                    setMachinesData(result)
                })
                .catch(error => {
                    console.log(error.response)
                    NotificationManager.error(error.response.data.error || error.response.data, 'Oops!', 3000);
                })
                .finally(() => {
                    setCountersDataGot(true)
                })
        })

    };




    const theme = useTheme();
    useEffect(() => {
        if (machinesData.length > 1) {

        } else {

            fetchMachinesData()
            console.log("123", machinesData)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    return (

        <React.Fragment>


            <Grid container spacing={0}>


                <Grid item xs={12} md={12} lg={12}>
                    {!countersDataGot
                        ?
                        <Paper className={fixedHeightSkeleton}>
                            <Skeleton animation="wave" width="100%" />
                            <Skeleton variant="circle" width={40} height={40} />
                            <Skeleton animation="wave" width="100%" />
                        </Paper>
                        :
                        <MaterialTable
                            style={{ boxShadow: "none" }}
                            columns={[
                                //{title: t('id'), field: "id", cellStyle: {textAlign: 'center'}},
                                { title: t('title'), field: "machine.name", cellStyle: { textAlign: 'center' } },
                                { title: t('model'), field: "machine.model", cellStyle: { textAlign: 'center' } },
                                { title: t('serial'), field: "machine.serial", cellStyle: { textAlign: 'center' } },

                            ]}


                            //parentChildData={(row, rows) => rows.find(description => description === row.parentId)}
                            localization={tableLocalizations[i18n.language]}
                            data={machinesData}
                            title={t('data_entered')}
                            options={{
                                actionsColumnIndex: -1,
                                actionsCellStyle: { border: 'none' },

                                header: true,

                                //  grouping: true,
                                search: false,
                                showTitle: true,
                                pageSize: 5,

                                // headerStyle: { textAlign: 'center' },
                                headerStyle: {
                                    width: 26,
                                    whiteSpace: 'nowrap',
                                    textAlign: 'center',
                                    flexDirection: 'row',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    backgroundColor: theme.palette.secondary.main,

                                    color: 'white',
                                },

                                rowStyle: {
                                    backgroundColor: '#EEEFF1', textAlign: 'center'
                                },



                                paginationType: 'stepped'
                            }}

                            detailPanel={[
                                {
                                    tooltip: 'Show Group Details',
                                    render: rowData => {
                                        console.log(rowData)
                                        return (


                                            <div style={{ marginTop: 24, marginBottom: 36 }}>


                                                <MaterialTable
                                                    style={{ boxShadow: "none", marginBottom: '25px' }}
                                                    columns={[

                                                        // { title: t('imei'), field: "imei", cellStyle: { textAlign: 'center' } },

                                                        {
                                                            title: t('data'),
                                                            field: "date",
                                                            render: rowData => (
                                                                <Moment format="DD/MM/YYYY" style={{ fontSize: 10 }}>
                                                                    {rowData.date}
                                                                </Moment>
                                                            ), cellStyle: { textAlign: 'center' }
                                                        },
                                                        { title: t('coin_in'), field: "coin_in", cellStyle: { textAlign: 'center' } },
                                                        // { title: t('address'), field: "address", cellStyle: { textAlign: 'left' } },
                                                        { title: t('key_in'), field: "key_in", cellStyle: { textAlign: 'center' } },
                                                        { title: t('key_out'), field: "key_out", cellStyle: { textAlign: 'center' } },


                                                    ]}
                                                    localization={tableLocalizations[i18n.language]}
                                                    data={rowData.indications}
                                                    title={t('stats')}
                                                    options={{
                                                        actionsColumnIndex: -1,
                                                        actionsCellStyle: { border: 'none' },
                                                        // actionsHeaderStyle: { border: 'none',  backgroundColor: '#1D69B9' },
                                                        header: true,
                                                        //  grouping: true,
                                                        search: false,
                                                        showTitle: true,
                                                        // pageSize: 5,
                                                        paging: false,
                                                        toolbar: false,

                                                        headerStyle: { textAlign: 'center', marginTop: '5px' },


                                                        rowStyle: { textAlign: 'center' }

                                                    }}

                                                />
                                            </div>

                                        )
                                    },
                                }
                            ]}

                        />
                    }
                </Grid>
            </Grid>



        </React.Fragment>
    );
}
