import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import { useTranslation } from "react-i18next";
import axios from "axios";
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function AddUserDialog(props) {

    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [newUser, setNewUser] = useState({
        login: '',
        password: '',
        name: '',
        is_manager: false
    });

    const onChange = ({ target: { name, value } }) => {
        console.log(name, value)
        value = name === 'is_manager' ? !!value : value;
        setNewUser({ ...newUser, [name]: value })
    };

    const handleManager = (event) => {
        onChange(({ target: { name: event.target.name, value: event.target.checked } }));
    };
    const handleOperator = (event) => {
        onChange(({ target: { name: event.target.name, value: event.target.checked } }));
    };

    const onSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        console.log('submitted')

        axios.post('/api/v1/users', newUser)
            .then(response => {
                if (response.data.id) {
                    NotificationManager.success(response.data.id, t('add_user'), 3000);
                    setTimeout(() => {
                        props.onAddUserSuccess();
                        handleClose();
                    }, 1000)
                }

            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
            })
    };

    return (
        <div style={{ padding: '0px 10px', textAlign: 'right' }}>
            <Button
                variant="outlined"
                color="secondary"
                startIcon={<GroupAddIcon />}
                onClick={handleClickOpen}
            >
                {t('add_user')}
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={"sm"}>
                <form onSubmit={onSubmit}>
                    <DialogTitle id="form-dialog-title">{t('add_user')}</DialogTitle>
                    <DialogContent>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t('name')}
                            name="name"
                            autoComplete="new-user-name"
                            autoFocus
                            value={newUser.name}
                            onInput={onChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t('login')}
                            name="login"
                            autoComplete="email"
                            value={newUser.login}
                            onInput={onChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t('password')}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={newUser.password}
                            onInput={onChange}
                        />
                        <FormControlLabel
                            value="top"
                            control={
                                <Checkbox
                                    checked={newUser.is_manager}
                                    name="is_manager"
                                    onChange={handleManager}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            }
                            label={t('is_manager')}
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="top"
                            control={
                                <Checkbox
                                    checked={newUser.is_operator}
                                    name="is_operator"
                                    onChange={handleOperator}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            }
                            label={t('is_operator')}
                            labelPlacement="end"
                        />
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={handleClose} variant={"outlined"} type={'reset'}>
                            {t('cancel')}
                        </Button>
                        <Button variant={"contained"} color="secondary" type={'submit'}>
                            {t('to_add')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
