import {combineReducers} from 'redux';
import {checkedItems, expandedItems, fetchSideMenuItems, menuOpen, previewOpen} from "./store";
import {fetchWidgetsAData, fetchWidgetsBData, fetchWidgetsCData} from "./widgets";
import {fetchCustomerInfo} from "./customer";

export const reducers = combineReducers({
    sideMenuOpened: menuOpen,
    previewSheetOpened: previewOpen,
    sideMenuExpanded: expandedItems,
    sideMenuChecked: checkedItems,
    sideMenuItems: fetchSideMenuItems,
    widgetsAData: fetchWidgetsAData,
    widgetsBData: fetchWidgetsBData,
    widgetsCData: fetchWidgetsCData,
    customerInfo: fetchCustomerInfo
});
