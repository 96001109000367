import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import axios from "axios";
import Skeleton from "@material-ui/lab/Skeleton";
import MaterialTable, {MTableToolbar} from "material-table";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import BlockIcon from '@material-ui/icons/Block';
import tableLocalizations from '../common/tableLocalizations.json';
import AddUserDialog from "./UserAddDialog";
import EditUserDialog from "./UserEditDialog";
import DeleteUserDialog from "./UserDeleteDialog";
import {isAuthenticated} from "../../plugins/AuthService";
import {NotificationManager} from "react-notifications";

const useStyles = makeStyles((theme) => ({
    depositContext: {
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        textAlign: 'center'
    },
    fixedHeight: {
        height: 180,
    },
    fixedHeightSkeleton: {
        height: 180,
        justifyContent: 'center',
        alignItems: 'center'
    },
}));

export default function UsersList(props) {
    const {t, i18n} = useTranslation()
    const classes = useStyles();
    const fixedHeightSkeleton = clsx(classes.paper, classes.fixedHeightSkeleton);
    const [usersData, setUsersData] = useState({});
    const [selectedUsersData, setSelectedUsersData] = useState(null);

    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setSelectedUsersData(null);
        fetchUsersData();
    };

    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setSelectedUsersData(null);
        fetchUsersData();
    };

    const fetchUsersData = () => {

        return new Promise((resolve, reject) => {
            axios.get('/api/v1/users')
                .then(response => {
                    return isAuthenticated().nam.toUpperCase() === 'ROOT' ? response.data.users : response.data.users.filter(user => user.login !== 'ROOT') || [];
                })
                .then(result => {
                    setUsersData(result)
                })
                .catch(error => {
                    console.log(error.response)
                    NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
                })
        })

    };

    useEffect(() => {
        if (usersData.counters) {
        } else {
            fetchUsersData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <Grid container spacing={0}>

                <Grid item xs={12} md={12} lg={12}>
                    {!usersData.length
                        ?
                        <Paper className={fixedHeightSkeleton}>
                            <Skeleton animation="wave" width="100%"/>
                            <Skeleton variant="circle" width={40} height={40}/>
                            <Skeleton animation="wave" width="100%"/>
                        </Paper>
                        :
                        <MaterialTable
                            style={{boxShadow: "none"}}
                            columns={[
                                {title: t('id'), field: "id", cellStyle: {textAlign: 'center'}},
                                {title: t('username'), field: "login", cellStyle: {textAlign: 'center'}},
                                {title: t('name'), field: "name", cellStyle: {textAlign: 'center'}},
                                {
                                    title: t('is_manager'),
                                    field: "is_manager",
                                    cellStyle: {textAlign: 'center'},
                                    render: rowData => {
                                        return rowData.is_manager ? <VerifiedUserIcon color={"secondary"}/> :
                                            <BlockIcon/>
                                    }
                                },
                            ]}
                            localization={tableLocalizations[i18n.language]}
                            data={usersData}
                            title={t('users')}
                            options={{
                                // actionsColumnIndex: -1,
                                actionsCellStyle: {color: 'red'},
                                header: true,
                                search: false,
                                showTitle: true,
                                pageSize: 5,
                                headerStyle: {textAlign: 'center'},
                                rowStyle: {textAlign: 'center'},
                                paginationType: 'stepped'
                            }}
                            components={{
                                Toolbar: props => (
                                    <div>
                                        <AddUserDialog onAddUserSuccess={fetchUsersData}/>
                                        <MTableToolbar {...props} />
                                    </div>
                                ),
                            }}
                            onRowClick={((event, rowData) => {
                                setOpenEditDialog(true);
                                setSelectedUsersData(rowData)
                            })}
                            actions={[
                                {
                                    icon: 'delete',
                                    tooltip: 'Delete User',
                                    onClick: (event, rowData) =>{
                                        setOpenDeleteDialog(true);
                                        setSelectedUsersData(rowData)
                                    }
                                }
                            ]}
                        />
                    }
                </Grid>
            </Grid>
            {
                selectedUsersData
                    ? <EditUserDialog
                        isOpen={openEditDialog}
                        closeDialog={handleCloseEditDialog}
                        userData={selectedUsersData}/>
                    : <span/>
            }

            {
                selectedUsersData
                    ? <DeleteUserDialog
                        isOpen={openDeleteDialog}
                        closeDialog={handleCloseDeleteDialog}
                        userData={selectedUsersData}/>
                    : <span/>
            }
        </React.Fragment>
    );
}
