import {makeStyles} from '@material-ui/core/styles';

const titleStyles = makeStyles(theme => ({
    dark: {},
    title: {
        display: 'block',
        position: 'relative',
        marginBottom: theme.spacing(3),
        textAlign: 'center',
        height: theme.spacing(8),
        background: `url(${theme.palette.type === 'dark' ? './images/profile/titleDecoDark.png' : './images/profile/titleDecoLight.png'}) no-repeat center center`,
        backgroundSize: 72,
        padding: theme.spacing(2, 0),
        '& h4': {
            color: theme.palette.secondary.main,
            verticalAlign: 'middle',
            fontWeight: theme.typography.fontWeightRegular,
            textTransform: 'capitalize',
            fontSize: 36,
            lineHeight: '56px',
            [theme.breakpoints.down('md')]: {
                fontSize: 32,
                lineHeight: '48px'
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: 24,
                lineHeight: '36px',
            },
            '& strong': {
                fontWeight: theme.typography.fontWeightBold,
                color: theme.palette.primary.main,
            }
        },
        '&$dark': {
            '& h4': {
                color: theme.palette.common.white,
            }
        }
    }
}));

export default titleStyles;
