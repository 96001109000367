import React, { useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslation} from "react-i18next";
import axios from "axios";
import 'react-notifications/lib/notifications.css';
import { NotificationManager} from 'react-notifications';



export default function EditImeiDialog(props) {

    const {t} = useTranslation();

    const handleClose = () => {
        props.closeDialog()
    };

    const [editImeiData, setEditImeiData] = useState({...{test:"test"}, ...props.imeiData});
    //setNewImei(props.imeiData)
    
    const onChange = ({target: {name, value}}) => {
       
        setEditImeiData({...editImeiData, [name]: value})
    };

    

    const onSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
       
        axios.put('/api/v1/imeis/'+ props.imeiData.imei,editImeiData)
        //+ props.userData.id, newUser)
            .then(response => {
                if(response.data.status){
                    NotificationManager.success(response.data.status, t('edit_imei_end'), 3000);
                    setTimeout(()=>{
                        handleClose();
                    },1000)
                }
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error(error.response.data.error || error.response.data, 'Oops!', 3000);
            })
    };

  

    return (
        <div style={{padding: '0px 10px', textAlign: 'right'}}>
            <Dialog open={props.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={"sm"}>
                <form onSubmit={onSubmit}>
                    <DialogTitle id="form-dialog-title">{t('edit_imei')}</DialogTitle>
                    <DialogContent>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            
                            fullWidth
                            label={t('name')}
                            name="name"
                            autoComplete="new-imei-name"
                            autoFocus
                            value={editImeiData.name}
                            onInput={onChange}
                        />
                         <TextField
                            variant="outlined"
                            margin="normal"
                            
                            fullWidth
                            label={t('address')}
                            name="address"
                            autoComplete="new-imei-address"
                            
                            value={editImeiData.address}
                            onInput={onChange}
                        />
                         <TextField
                            variant="outlined"
                            margin="normal"
                            
                            fullWidth
                            label={t('model')}
                            name="model"
                            autoComplete="new-imei-model"
                            
                            value={editImeiData.model}
                            onInput={onChange}
                        />
                         <TextField
                            variant="outlined"
                            margin="normal"
                            
                            fullWidth
                            label={t('serial')}
                            name="serial"
                            autoComplete="new-imei-serial"
                           
                            value={editImeiData.serial}
                            onInput={onChange}
                        />
                        
                    </DialogContent>
                    <DialogActions style={{justifyContent: 'space-between'}}>
                        <Button onClick={handleClose} variant={"outlined"} type={'reset'}>
                            {t('cancel')}
                        </Button>
                        <Button variant={"contained"} color="secondary" type={'submit'}>
                            {t('to_edit')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
