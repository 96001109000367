/* Actions */
export const setWidgetA = (widgetsAData) => ({
    type: 'SET_WIDGETS_A_DATA',
    widgetsAData

});
export const setWidgetB = (widgetsBData) => ({
    type: 'SET_WIDGETS_B_DATA',
    widgetsBData

});

export const setWidgetC = (widgetsCData) => ({
    type: 'SET_WIDGETS_C_DATA',
    widgetsCData

});


/* Reducers */

export const fetchWidgetsAData = (state = [], action) => {
    switch (action.type) {
        case 'SET_WIDGETS_A_DATA':
            return action.widgetsAData
        default:
            return state
    }
};

export const fetchWidgetsBData = (state = [], action) => {
    switch (action.type) {
        case 'SET_WIDGETS_B_DATA':
            return action.widgetsBData
        default:
            return state
    }
};

export const fetchWidgetsCData = (state = [], action) => {
    switch (action.type) {
        case 'SET_WIDGETS_C_DATA':
            return action.widgetsCData
        default:
            return state
    }
};




