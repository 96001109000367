import React, {useEffect, useState} from 'react';
import DocumentTitle from 'react-document-title';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import WaveBorder from "../common/WaveBorder";
import theme from "../../theme";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {isWidthUp} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import WaterWave from 'react-water-wave';
import Gallery from "../Gallery";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    wrapper: {
        position: "relative",
        backgroundColor: theme.palette.secondary.main,
        paddingBottom: theme.spacing(2),
        backgroundImage: 'url(./images/home_underwater_sectionbg1.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center top',
        height: 'calc(100vh - 130px)',
        maxHeight: 720,
        display: 'flex',
        alignItems: 'center'
    },
    image: {
        maxWidth: "100%",
        verticalAlign: "middle",
    },
    container: {
        width: '100%',
        zIndex: 1,
        pointerEvents: 'none'
    },
    waveBorderTop: {
        marginTop: 64
    },
    waveBorderBottom: {},
    extraLargeButtonLabel: {
        fontSize: theme.typography.body1.fontSize,
        textShadow: 'none',
        [theme.breakpoints.up("sm")]: {
            fontSize: theme.typography.h6.fontSize
        }
    },
    extraLargeButton: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        maxWidth: 500,
        margin: '0 auto',
        [theme.breakpoints.up("xs")]: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
        },
        [theme.breakpoints.up("lg")]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        },
        pointerEvents: 'auto'
    },
    card: {
        boxShadow: theme.shadows[0],
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        background: "transparent",
        width: "100%",
        textAlign: "center",
        [theme.breakpoints.up("xs")]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3)
        },
        [theme.breakpoints.up("sm")]: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4)
        },
        [theme.breakpoints.up("md")]: {
            paddingTop: theme.spacing(5.5),
            paddingBottom: theme.spacing(5.5),
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5)
        },
        [theme.breakpoints.up("lg")]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6)
        },
        [theme.breakpoints.down("lg")]: {
            // width: "auto"
            width: '100%'
        }
    },
    containerFix: {
        [theme.breakpoints.up("md")]: {
            maxWidth: "none !important"
        },
        textShadow: "0 0 6px rgba(16, 30, 80, 1), 1px 0 1px rgba(0, 0, 0, 1), 0 1px 1px rgba(0, 0, 0, 1)",
        '& h1': {color: '#FFFF00'},
        '& h2': {color: '#FFFF00', fontWeight: 500, textTransform: 'uppercase'},
        '& h3': {color: '#FFFF00'},
        '& h4': {color: '#FFFF00', textTransform: 'uppercase'},
        '& h6': {color: '#FFF'},
        '& p': {color: '#FFF'}

    },
}));

export default function Home(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {width} = props;

    const [wg, setWg] = useState(false)

    function hasWebGL() {
        let canvas;
        let supported;
        let ext;

        try {
            canvas = document.createElement('canvas');
            const gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
            ext = gl.getExtension('OES_texture_float');
            if (gl && gl instanceof WebGLRenderingContext){
                supported = true;
            }
            // supported = !!(!!window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
        } catch (e) {
            supported = false;
        }

        canvas = undefined;

        return supported && !!ext;
    }


    useEffect(()=>{
        setWg(hasWebGL())
        // console.log(hasWebGL())
    },[])


    return (
        <DocumentTitle title={'FISH-Andromeda || ' + t('mainmenu.home')}>
            <React.Fragment>
            <Box>
                {/* <WaveBorder
                    upperColor="#FFFFFF"
                    lowerColor={theme.palette.secondary.main}
                    className={classes.waveBorderTop}
                    animationNegativeDelay={2}
                />*/}

                <div className={classes.wrapper}>

                    {
                        wg
                            ? <WaterWave
                                className={classes.wrapper}
                                style={{width: '100%', height: '100%', backgroundSize: 'cover', position: 'absolute'}}
                                imageUrl={'./images/home_underwater_sectionbg1.jpg'}
                            >
                                {({pause, play, drop}) => (
                                    /* children components */
                                    <span>{
                                        /*setTimeout(() => {
                                            play()
                                            drop({x: 0, y: 0, radius: 20, strength: 1})

                                            setInterval(() => {
                                                play()
                                                drop({x: 0, y: 0, radius: 20, strength: 1})
                                            }, 45000)


                                        }, 200)*/

                                    }</span>
                                )}
                            </WaterWave>
                            : <span/>
                    }


                    <div className={clsx("container-fluid", classes.container)}>

                        <Box display="flex" justifyContent="center" className="row">
                            <Card
                                className={classes.card}
                                data-aos-delay="200"
                                data-aos="zoom-in"
                            >
                                <div className={clsx(classes.containerFix, "container")}>
                                    <Box justifyContent="space-between" className="row">
                                        <Grid item xs={12} md={8} style={{margin: '0 auto'}}>
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="space-between"
                                                height="100%"
                                            >
                                                <Box mb={4}>
                                                    <Typography
                                                        variant={isWidthUp("lg", width) ? "h2" : "h4"}
                                                    >
                                                        Fish Games Tables & Accessories
                                                    </Typography>
                                                </Box>
                                                <div>
                                                    <Box mb={2}>
                                                        <Typography
                                                            /*variant={isWidthUp("lg", width) ? "h4" : "body1"}*/
                                                            variant={window.innerWidth >= theme.breakpoints.values.lg ? "h6" : "body1"}
                                                            color="textSecondary"
                                                        >
                                                            {t('header_desc')}
                                                        </Typography>
                                                    </Box>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        fullWidth
                                                        className={classes.extraLargeButton}
                                                        classes={{label: classes.extraLargeButtonLabel}}
                                                        href="https://fish-games.com.ua"
                                                    >
                                                        {t('header_btn')}
                                                    </Button>
                                                </div>
                                            </Box>
                                        </Grid>
                                        {/*<Hidden smDown>
                                        <Grid item md={6}>
                                            <img
                                                src={headerImage}
                                                className={classes.image}
                                                alt="header example"
                                            />
                                        </Grid>
                                    </Hidden>*/}
                                    </Box>
                                </div>
                            </Card>
                        </Box>
                    </div>
                </div>
                <WaveBorder
                    upperColor={theme.palette.secondary.main}
                    lowerColor="#FFFFFF"
                    className={classes.waveBorderBottom}
                    animationNegativeDelay={1}
                />
            </Box>
            <Box>
                <Gallery/>
            </Box>
            </React.Fragment>
        </DocumentTitle>
    );
};
