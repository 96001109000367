import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button, Hidden, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import BookIcon from "@material-ui/icons/Book";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import SvgIcon from "@material-ui/core/SvgIcon";
import Dialog from "@material-ui/core/Dialog";
import SignIn from "../../../components/common/Login/SingIn";
import NavigationDrawer from "./NavigationDrawer";
import { isAuthenticated, logout } from "../../../plugins/AuthService";

const useStyles = makeStyles(theme => ({
    appBar: {
        boxShadow: theme.shadows[6],
        backgroundColor: theme.palette.common.white
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between"
    },
    menuButtonText: {
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.h6.fontWeight
    },
    brandText: {
        fontFamily: "'Baloo Bhaijaan', cursive",
        fontWeight: 400
    },
    noDecoration: {
        textDecoration: "none !important"
    },
    image: {
        maxHeight: 36,
        margin: '0px 8px -8px 0'
    }
}));

export default function NavBar(props) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        localStorage.setItem('lang', lng);
        i18n.changeLanguage(lng);
    };

    const [open, setOpen] = React.useState(false);

    const [selectedTab] = useState(null);

    const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

    const handleMobileDrawerOpen = useCallback(() => {
        setIsMobileDrawerOpen(true);
    }, [setIsMobileDrawerOpen]);

    const handleMobileDrawerClose = useCallback(() => {
        setIsMobileDrawerOpen(false);
    }, [setIsMobileDrawerOpen]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const logOut = () => {
        logout(true);
    }

    const menuItems =

        isAuthenticated()
            ?
            [
                {
                    link: "./",
                    name: 'mainmenu.home',
                    icon: <HomeIcon className="text-white" />
                },
                {
                    link: "./store",
                    name: 'mainmenu.cabinets',
                    icon: <BookIcon className="text-white" />
                },
                {
                    link: "./store",
                    name: 'mainmenu.games',
                    icon: <BookIcon className="text-white" />
                },
                {
                    link: (isAuthenticated().adm ? "./admin" : isAuthenticated().operator ? "./operator" : "./dashboard"),

                    name: (isAuthenticated().adm ? 'mainmenu.admin' : isAuthenticated().operator ? "mainmenu.operator" : 'mainmenu.dashboard'),
                    icon: <BookIcon className="text-white" />
                },
                {
                    name: 'mainmenu.logout',
                    onClick: logOut,
                    icon: <LockOpenIcon className="text-white" />
                }

            ]
            :
            [
                {
                    link: "./",
                    name: 'mainmenu.home',
                    icon: <HomeIcon className="text-white" />
                },
                {
                    link: "./store",
                    name: 'mainmenu.cabinets',
                    icon: <BookIcon className="text-white" />
                },
                {
                    link: "./store",
                    name: 'mainmenu.games',
                    icon: <BookIcon className="text-white" />
                },

                {
                    name: 'mainmenu.login',
                    onClick: handleClickOpen,
                    icon: <LockOpenIcon className="text-white" />
                }

            ]


    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <div>
                        <Typography
                            variant="h4"
                            className={classes.brandText}
                            display="inline"
                            color="secondary"
                        >
                            <img
                                src='./images/logo_fish_128.png'
                                className={classes.image}
                                alt="header example"
                            />
                        </Typography>
                        <Typography
                            variant="h4"
                            className={classes.brandText}
                            display="inline"
                            color="secondary"
                        >
                            Fish-
                        </Typography>
                        <Typography
                            variant="h4"
                            className={classes.brandText}
                            display="inline"
                            color="primary"
                        >
                            Games.com.ua
                        </Typography>
                    </div>
                    <div>
                        <Hidden mdUp>
                            <IconButton
                                className={classes.menuButton}
                                onClick={handleMobileDrawerOpen}
                                aria-label="Open Navigation"
                            >
                                <MenuIcon color="primary" />
                            </IconButton>
                        </Hidden>
                        <Hidden smDown>
                            {
                                menuItems.map(element => {
                                    if (element.link) {
                                        return (
                                            <Link
                                                key={element.name}
                                                to={element.link}
                                                className={classes.noDecoration}
                                                onClick={handleMobileDrawerClose}
                                            >
                                                <Button
                                                    color="secondary"
                                                    size="large"
                                                    classes={{ text: classes.menuButtonText }}
                                                >
                                                    {t(element.name)}
                                                </Button>
                                            </Link>
                                        );
                                    }
                                    return (
                                        <Button
                                            color="secondary"
                                            size="large"
                                            onClick={element.onClick}
                                            classes={{ text: classes.menuButtonText }}
                                            key={element.name}
                                        >
                                            {t(element.name)}
                                        </Button>
                                    );

                                })
                            }

                            <IconButton aria-label="polish" onClick={() => changeLanguage('uk')}>
                                <SvgIcon style={{ marginTop: 4, marginBottom: -4 }}>
                                    <rect width="30" height="12" y="2" fill="#005bbb" />
                                    <rect width="30" height="6" fill="#ffd500" y="8" />
                                </SvgIcon>
                            </IconButton>
                            <IconButton aria-label="polish" onClick={() => changeLanguage('en')}>
                                <SvgIcon viewBox="0 0 30 24" style={{ marginTop: 4, marginBottom: -4 }}>
                                    <clipPath id="t">
                                        <path d="M15,8 h15 v8 z v8 h-15 z h-15 v-8 z v-8 h15 z" />
                                    </clipPath>
                                    <path d="M0,0 v15 h30 v-15 z" fill="#00247d" />
                                    <path d="M0,0 L30,15 M30,0 L0,15" stroke="#fff" strokeWidth="3" />
                                    <path d="M0,0 L30,15 M30,0 L0,15" clipPath="url(#t)" stroke="#cf142b"
                                        strokeWidth="2" />
                                    <path d="M15,0 v15 M0,8 h30" stroke="#fff" strokeWidth="5" />
                                    <path d="M15,0 v15 M0,8 h30" stroke="#cf142b" strokeWidth="3" />
                                </SvgIcon>
                            </IconButton>


                        </Hidden>
                    </div>
                </Toolbar>
            </AppBar>

            <NavigationDrawer
                menuItems={menuItems}
                anchor="right"
                open={isMobileDrawerOpen}
                selectedItem={selectedTab}
                onClose={handleMobileDrawerClose}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <SignIn />
            </Dialog>

        </div>
    );
}

