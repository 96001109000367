import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslation} from "react-i18next";
import axios from "axios";
import 'react-notifications/lib/notifications.css';
import { NotificationManager} from 'react-notifications';
import DeleteRoomDialog from "./RoomDeleteDialog";

export default function EditRoomDialog(props) {

    const {t} = useTranslation();
    const [selectedRoomsData, setSelectedRoomsData] = useState(null);
    const [roomsData, setRoomsData] = useState({});
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

    const handleClose = () => {
        props.closeDialog()
    };

    const [newRoom, setNewRoom] = useState({...{password:''},...props.roomData});

    const onChange = ({target: {name, value}}) => {
        //console.log(name, value)
       // value = name === 'is_manager' ? !!value : value;
        setNewRoom({...newRoom, [name]: value})
    };

    const onSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        axios.put('/api/v1/rooms/' + props.roomData.ID, newRoom)
            .then(response => {
                if(response.data.room.ID){
                    NotificationManager.success(response.data.room.ID, t('edit_room'), 3000);
                    setTimeout(()=>{
                        handleClose();
                    },1000)
                }
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error(error.response.data.error || error.response.data, 'Oops!', 3000);
            })
    };




   
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        props.closeDialog();

        //setSelectedRoomsData(null);
        //fetchRoomsData();
    };
    const fetchRoomsData = () => {

        return new Promise((resolve, reject) => {
            axios.get('/api/v1/rooms')
                /* .then(response => {
                    return isAuthenticated().nam.toUpperCase() === 'ROOT' ? response.data.users : response.data.users.filter(user => user.login !== 'ROOT') || [];
                }) */
                .then(result => {
                    setRoomsData(result.data.rooms)
                    console.log(result)
                })
                .catch(error => {
                    console.log(error.response)
                    NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
                })
        })

    };
    useEffect(() => {
        if (roomsData.rooms) {
        } else {
            fetchRoomsData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

     const handleDelete = () => {
        
        setOpenDeleteDialog(true);

        setSelectedRoomsData(props.roomData);
       
    }
 
    

    return (
        <div style={{padding: '0px 10px', textAlign: 'right'}}>
            <Dialog open={props.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={"sm"}>
                <form onSubmit={onSubmit}>
                    <DialogTitle id="form-dialog-title">{t('edit_room')}</DialogTitle>
                    <DialogContent>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={t('title')}
                            name="name"
                            autoComplete="new-room-name"
                            autoFocus
                            value={newRoom.name}
                            onInput={onChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="address"
                            label={t('address')}
                            autoComplete="new-room-address"
                            value={newRoom.address}
                            onInput={onChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="description"
                            label={t('description')}
                            autoComplete="new-room-description"
                            value={newRoom.description}
                            onInput={onChange}
                        />
                    </DialogContent>
                   
                    <DialogActions style={{justifyContent: 'space-between'}}>
                        <Button onClick={handleClose} variant={"outlined"} type={'reset'}>
                            {t('cancel')}
                        </Button>
                        <Button onClick= {handleDelete} variant={"contained"} type={'button'} style={{background: 'red', color: 'white'}}>
                            {t('to_delete')}
                        </Button>
                        
                        <Button variant={"contained"} color="secondary" type={'submit'}>
                            {t('to_edit')}
                        </Button>
                        
                    </DialogActions>
                </form>
               
                
            </Dialog>


            {
                selectedRoomsData
                    ? <DeleteRoomDialog
                        isOpen={openDeleteDialog}
                        closeDialog={handleCloseDeleteDialog}
                        roomData={selectedRoomsData}/>
                    : <span/>
            }

            
        </div>
    );
}
