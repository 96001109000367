import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from "date-fns/locale/en-US";
import ukLocale from "date-fns/locale/uk";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
//import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from '@material-ui/core/styles';
import theme from "../../../theme";
import lightBlue from "@material-ui/core/colors/lightBlue";
import { useTranslation } from "react-i18next";

const materialTheme = createTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: theme.palette.secondary.main,
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                // backgroundColor: lightBlue.A200,
                // color: "white",
            },
        },
        MuiPickersDay: {
            day: {
                color: lightBlue.A700,
            },
            daySelected: {
                backgroundColor: lightBlue["400"],
            },
            dayDisabled: {
                color: lightBlue["100"],
            },
            current: {
                color: lightBlue["900"],
            },
        },
        MuiPickersModal: {
            /*dialogAction: {
                color: theme.palette.primary.main,
            },*/
        },
    },
});

const localeMap = {
    en: enLocale,
    uk: ukLocale,
};

export default function CounterDatePicker(props) {
    const { i18n } = useTranslation();
    const [selectedDate, setSelectedDate] = React.useState(new Date());

    const handleDateChange = (date) => {
        console.log("close change: ", date)
        setSelectedDate(date);
        props.setNewDate(date);
    };


    const handleClose = () => {
        //console.log("close timer: ", selectedDate)
        //props.setNewDate(selectedDate)
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
            <ThemeProvider theme={materialTheme}>
                <DatePicker
                    variant={'dialog'}
                    DialogProps={{ maxWidth: 'xs' }}
                    ampm={false}
                    hideTabs={true}
                    value={selectedDate}
                    onChange={handleDateChange}
                    format={"dd/MM/yyyy"}
                    onClose={handleClose}
                />
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
}
