import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslation} from "react-i18next";
import axios from "axios";
import 'react-notifications/lib/notifications.css';
import { NotificationManager} from 'react-notifications';
import Typography from "@material-ui/core/Typography";
import { useParams } from "react-router-dom";

export default function UnlinkImeiDialog(props) {

    const {t} = useTranslation();
    const { room_id } = useParams();

    const handleClose = () => {
        props.closeDialog()
    };

    //const [newUser] = useState({...{password:''},...props.userData});

    const onSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        axios.delete(`/api/v1/rooms/${room_id}/imeis/${props.imei}`)
      
            .then(response => {
                    NotificationManager.success(response.data.id, t('unlink_imei'), 3000);
                    setTimeout(()=>{
                        handleClose();
                    },1000)
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error(error.response.data.error || error.response.data, 'Oops!', 3000);
            })
    };

    return (
        <div style={{padding: '0px 10px', textAlign: 'right'}}>
            <Dialog open={props.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={"sm"}>
                <form onSubmit={onSubmit}>
                    <DialogTitle id="form-dialog-title" style={{background: 'red', color: 'white', textAlign: 'center'}}>
                        {t('unlink_imei')}
                    </DialogTitle>
                    <DialogContent style={{textAlign:'center'}}>
                        <Typography variant={"h5"} style={{margin:'12px auto'}}> {t('unlink_imei_prompt')}</Typography>
                        <Typography variant={"h4"} style={{margin:'12px auto'}}> {props.imei}</Typography>
                        <Typography variant={"h3"} component={"i"} style={{margin:'12px auto'}}> {t('R_U_sure')}</Typography>
                    </DialogContent>
                    <DialogActions style={{justifyContent: 'space-between'}}>
                        <Button onClick={handleClose} variant={"outlined"} type={'reset'}>
                            {t('cancel')}
                        </Button>
                        <Button variant={"contained"} type={'submit'} style={{background: 'red', color: 'white'}}>
                            {t('to_delete')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
