import React from 'react';
//import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import OperatorDrawer from './Drawer';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
//import {useTranslation} from "react-i18next";
import CountersListOperator from "../common/Counters/CountersListOperator";


const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolBarUser: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'left',
        paddingLeft: 16,
        alignItems: 'center',
        color: theme.palette.primary.main
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 8,
        marginLeft: 36,
        [theme.breakpoints.down("sm")]: {
            marginRight: -5
        }
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginTop: 65
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    drawerList: {
        '& a': {
            textDecoration: 'inherit',
            color: 'inherit'
        }
    },
    drawerListItem: {
        borderBottom: '1px solid #EEE'
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function Dashboard() {
    // const {t} = useTranslation()
    const classes = useStyles();

    //const [open, setOpen] = React.useState(true);
    /* const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    }; */
    /*  return (
         <div className={classes.root}>
             <CssBaseline />
             <OperatorDrawer />
             <main className={classes.content}>
                 <div className={classes.appBarSpacer} />
                 <Container maxWidth="lg" className={classes.container}>
                     <Divider />
                    // <CountersListClient />
                     <Divider />
                 </Container>
             </main>
         </div>
     );
 } */
    // const currentUser = isAuthenticated();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <OperatorDrawer />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Divider />
<CountersListOperator/>
                    <Divider />
                </Container>
            </main>
        </div>
    );
}
