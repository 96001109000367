import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslation} from "react-i18next";
import axios from "axios";
import 'react-notifications/lib/notifications.css';
import {NotificationManager} from 'react-notifications';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        display: 'flex',
        flexGrow: 1
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function LinkImeiRoomDialog(props) {
    const classes = useStyles();

    const {t} = useTranslation();

    const handleClose = () => {
        props.closeDialog()
    };

    const [newUser, setNewUser] = useState(props.id);
    //(props.userData.id);
    const [usersData, setUsersData] = useState({});

    const handleChange = (event) => {
        setNewUser(event.target.value);
        console.log("sss ", newUser);
    };

    const fetchUsersData = () => {

        return new Promise((resolve, reject) => {
            axios.get('/api/v1/users')
                .then(response => {
                    return response.data.users.filter(user => !user.is_manager) || [];
                })
                .then(result => {
                    setUsersData(result)
                })
                .catch(error => {
                    console.log(error.response)
                    NotificationManager.error(error.response ? error.response.data.error || error.response.data: 'Error', 'Oops!', 3000);
                })
        })

    };

    useEffect(() => {
        if (usersData.counters) {
        } else {
            fetchUsersData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

       //axios.put('api/v1/rooms/users/' )
       axios.post (`api/v1/rooms/${props.roomData.ID}/users`, {user_id: newUser})
            .then(response => {
                console.log(response);
                newUser === 0
                ? NotificationManager.success(t('no_linked_user'), t('is_linked_imei'), 3000)
                : NotificationManager.success(usersData.filter(u => u.id === newUser)[0].name, t('is_linked_imei'), 3000)
                setTimeout(() => {
                    handleClose();
                }, 1000)
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error(error.response ? error.response.data.error || error.response.data: 'Error', 'Oops!', 3000);
            })
    };

    return (
        <div style={{padding: '0px 10px', textAlign: 'right'}}>
            <Dialog open={props.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={"sm"}>
                <form onSubmit={onSubmit}>
                    <DialogTitle id="form-dialog-title">{t('link_user')}</DialogTitle>
                    <Divider/>
                    <DialogContent>
                        <Grid container style={{alignItems: 'center', marginTop: 24, marginBottom: 36}}>
                            <Grid item xs={12} md={5}>
                                <Typography variant={"h6"} style={{margin: '12px auto'}}>
                                    {props.roomData.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={7}>

                                {!usersData.length
                                    ?
                                    <Paper>
                                        <Skeleton animation="wave" width="100%"/>
                                        <Skeleton variant="circle" width={40} height={40}/>
                                        <Skeleton animation="wave" width="100%"/>
                                    </Paper>
                                    :
                                    <FormControl variant={'outlined'} className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">{t('user')}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={newUser}
                                            onChange={handleChange}
                                            label={t('user')}
                                        >
                                            {/* <MenuItem value={0}>{t('no_linked_user')}</MenuItem> */}
                                            {
                                                usersData.map(user => <MenuItem value={user.id} key={user.id}>{user.name}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                }

                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider/>
                    <DialogActions style={{justifyContent: 'space-between'}}>
                        <Button onClick={handleClose} variant={"outlined"} type={'reset'}>
                            {t('cancel')}
                        </Button>
                        <Button variant={"contained"} color="secondary" type={'submit'}>
                            {t('to_link')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
