import React from 'react';
//import {BrowserRouter as Router, Switch} from 'react-router-dom';
import { HashRouter as Router, Switch } from 'react-router-dom';
import MainLayoutRoutes from "./layouts/Main/route";
import EmptyLayoutRoutes from "./layouts/Empty/route";
import DashboardLayoutRoutes from "./layouts/Dashboard/route";
import OperatorLayoutRoutes from "./layouts/Operator/route";
import AdminLayoutRoutes from "./layouts/Admin/route";
import Home from "./components/Home";
import Page404 from "./components/404";
import Page401 from "./components/401";
import DashboardIndex from "./components/Dashboard";
import AdminIndex from "./components/Admin";
import OperatorIndex from "./components/Operator";
import AdminUsers from "./components/Admin/Users";
import AdminImeis from "./components/Admin/Imeis";
import AdminStats from "./components/Admin/Stats";
import AdminRooms from "./components/Admin/Rooms";
import AdminURoom from "./components/Admin/URoom";
import AdminData from "./components/Admin/Data";
import AdminDataM from "./components/Admin/DataM";
import DashboardStats from "./components/Dashboard/Stats";
import OperatorData from "./components/Operator/Data";




const warn = console.warn;

export function logWarning(...warnings) {
    let showWarning = true;
    warnings.forEach(warning => {
        if (warning.includes("UNSAFE_")) showWarning = false;
        else if (warning.includes("SourceMap")) showWarning = false;
        else if (warning.includes("DevTools")) showWarning = false;
        else if (warning.includes("WebSocket")) showWarning = false;
        else if (warning.includes("import SvgIcon")) showWarning = false;
    });
    if (showWarning) warn(...warnings);
}

console.warn = logWarning;

export default function App() {
    return (
        <Router>
            <Switch>
                <MainLayoutRoutes exact path="/" component={Home} />
                <DashboardLayoutRoutes exact path="/dashboard" component={DashboardIndex} />
                <DashboardLayoutRoutes exact path="/dashboard/stats" component={DashboardStats} />
                <OperatorLayoutRoutes exact path="/operator" component={OperatorIndex} />
                <OperatorLayoutRoutes exact path="/operator/data" component={OperatorData} />



                <AdminLayoutRoutes exact path="/admin" component={AdminIndex} />
                <AdminLayoutRoutes exact path="/admin/stats" component={AdminStats} />
                <AdminLayoutRoutes exact path="/admin/users" component={AdminUsers} />
                <AdminLayoutRoutes exact path="/admin/rooms" component={AdminRooms} />
                <AdminLayoutRoutes exact path="/admin/data" component={AdminData} />
                 <AdminLayoutRoutes exact path="/admin/datam" component={AdminDataM} />

                <AdminLayoutRoutes exact path="/admin/rooms/:room_id" component={AdminURoom} />
                <AdminLayoutRoutes exact path="/admin/imeis" component={AdminImeis} />
                <EmptyLayoutRoutes path="/denied" component={Page401} />
                <EmptyLayoutRoutes path="/*" component={Page404} />
            </Switch>
        </Router>
    );
};
