import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import OperatorLayout from "./index";
import * as AuthService from "../../plugins/AuthService";


const OperatorLayoutRoutes = ({ component: Component, ...rest }) => {

    return (
        <Route {...rest} render={props =>

            AuthService.isAuthenticated().operator && !AuthService.isAuthenticated().adm 
                ?
                (
                    <OperatorLayout>
                        <Component {...props} />
                    </OperatorLayout>
                )
                : (
                    <Redirect
                        to={{
                            pathname: "/denied",
                            state: { from: props.location }
                        }}
                    />
                )


        } />
    )
}

export default OperatorLayoutRoutes
