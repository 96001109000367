import React from 'react';
import DocumentTitle from 'react-document-title';
import { useTranslation } from "react-i18next";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import MachinesList from "./MachinesList";

import OperatorDrawer from "./Drawer";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },

    appBarSpacer: theme.mixins.toolbar,

    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
}));

export default function MList() {
    const { t } = useTranslation();
    const classes = useStyles();

    return (

        <DocumentTitle title={'FISH-Andromeda || ' + t('mainmenu.operator')}>
            <div className={classes.root}>
                <CssBaseline />
                <OperatorDrawer />

                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <MachinesList />
                    </Container>
                </main>
            </div>
        </DocumentTitle>
    );
};
