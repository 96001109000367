import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import axios from "axios";
import Skeleton from "@material-ui/lab/Skeleton";
import MaterialTable from "material-table";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import BlockIcon from '@material-ui/icons/Block';
import tableLocalizations from '../common/tableLocalizations.json';
//import AddUserDialog from "./UserAddDialog";
//import EditUserDialog from "./UserEditDialog";
//import DeleteUserDialog from "./UserDeleteDialog";
import { isAuthenticated } from "../../plugins/AuthService";
import { NotificationManager } from "react-notifications";
import { useParams } from "react-router-dom";
//import AdminDrawer from "./Drawer";
//import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Button from '@material-ui/core/Button';
//import AddUserDialog from "./UserAddDialog";
import TextField from '@material-ui/core/TextField';
//import InputAdornment from '@material-ui/core/InputAdornment';
//import Chip from '@material-ui/core/Chip';
//import Avatar from '@material-ui/core/Avatar';
import LinkIcon from '@material-ui/icons/Link';
//import { red } from '@material-ui/core/colors';
import LinkImeiDialog from "./RoomImeiLinkDialog";
import LinkUserDialog from "./RoomUserLinkDialog";
import UnlinkImeiDialog from "./UnlinkImeiDialog";
import UnlinkUserDialog from "./UnlinkUserDialog";



const useStyles = makeStyles((theme) => ({
    depositContext: {
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        textAlign: 'center'
    },
    fixedHeight: {
        height: 180,
    },
    fixedHeightSkeleton: {
        height: 180,
        justifyContent: 'center',
        alignItems: 'center'
    },
    root: {
        // display: 'flex',
        // flexDirection: 'row-reverse',

        alignItems: 'left',
        justifyContent: 'center',
        color: '#019fdc',
        borderColor: '#019fdc',

        margin: theme.spacing(0.5),

    },

}));

export default function RoomList(props) {
    const { t, i18n } = useTranslation()
    const classes = useStyles();
    const fixedHeightSkeleton = clsx(classes.paper, classes.fixedHeightSkeleton);
    const [roomData, setRoomData] = useState({});
    const [usersData, setUsersData] = useState({});
    const { room_id } = useParams();

    const [imeisData, setImeisData] = useState({});
    const [selectedRoomsData, setSelectedRoomsData] = useState(null);
    const [openLinkImeiDialog, setOpenLinkImeiDialog] = React.useState(false);
    const [openLinkUserDialog, setOpenLinkUserDialog] = React.useState(false);
    const [openUnlinkImeiDialog, setOpenUnlinkImeiDialog] = React.useState(false);
    const [openUnlinkUserDialog, setOpenUnlinkUserDialog] = React.useState(false);
    const [selectedUsersData, setSelectedUsersData] = useState(null);
    const [selectedImeisData, setSelectedImeisData] = useState(null);

    const handleCloseLinkDialog = () => {
        setOpenLinkUserDialog(false);
        setOpenLinkImeiDialog(false);
        setOpenUnlinkImeiDialog(false);
        setOpenUnlinkUserDialog(false);
        setSelectedRoomsData(null);
       // fetchImeisData();
       fetchRoomData ();
    }



    const fetchRoomData = () => {

        return new Promise((resolve, reject) => {
            //console.log(room_id)
            axios.get(`/api/v1/rooms/${room_id}`)
                .then(response => {
                    return isAuthenticated().nam.toUpperCase() === 'ROOT' ? response.data : [];
                })
                .then(result => {
                    if (result.room) {
                        setRoomData(result.room)
                    }
                    if (result.users !== null) {
                        if (result.users.length > 0) {
                            setUsersData(result.users)
                        }
                    }
                    if (result.imeis !== null) {
                        if (result.imeis.length > 0) {
                            setImeisData(result.imeis)
                        }
                    }
                })
                .catch(error => {
                    console.log(error.response)
                    NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
                })
        })

    };

    useEffect(() => {

        if (roomData.ID !== undefined) {

            //if (usersData.counters) {
        } else {
            fetchRoomData()
        }
    

        if (imeisData.imeis) {
        } else {
            fetchRoomData()
        }
        // if (usersData.length !== undefined) {
        //     //if (usersData.counters) {
        //     } else {
        //      //   fetchUsersData()
        //     }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (


        <React.Fragment>

            <Grid container spacing={4}>

                <Grid item xs={12} md={3} lg={3}>
                    {!roomData.ID
                        ?
                        <span> </span>
                        :
                        <div style={{ padding: '0px 10px', textAlign: 'left' }}>
                            <TextField className={classes.root}
                                id="0"
                                label={t('id')}
                                defaultValue={roomData.ID}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />

                        </div>}

                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    {!roomData.ID
                        ?
                        <span> </span>
                        :
                        <div style={{ padding: '0px 10px', textAlign: 'left' }}>
                            <TextField className={classes.root}
                                id="1"
                                label={t('title')}
                                defaultValue={roomData.name}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />

                        </div>}

                </Grid>

                <Grid item xs={12} md={3} lg={3}>
                    {!roomData.ID
                        ?
                        <span> </span>
                        :
                        <div style={{ padding: '0px 10px', textAlign: 'left' }}>
                            <TextField className={classes.root}
                                id="2"
                                label={t('address')}
                                defaultValue={roomData.address}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />

                        </div>}

                </Grid>

                <Grid item xs={12} md={3} lg={3}>
                    {!roomData.ID
                        ?
                        <span> </span>
                        :
                        <div style={{ padding: '0px 10px', textAlign: 'left' }}>
                            <TextField className={classes.root}
                                id="3"
                                label={t('description')}
                                defaultValue={roomData.description}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />

                        </div>}

                </Grid>
            </Grid>
            <Grid container spacing={2}>

                <Grid item xs={12} md={3} lg={3}>
                    {!roomData.ID
                        ?
                        <span> </span>
                        :
                        <span>  </span>
                    }
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                    {!roomData.ID
                        ?
                        <span> </span>
                        :
                        <div style={{ padding: '10px 10px', textAlign: 'right' }}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<LinkIcon />}
                                onClick={() => {
                                   setSelectedRoomsData(roomData);
                                   setOpenLinkImeiDialog(true)
                                }}
                            >
                                {t('imei')}
                            </Button>
                        </div>}

                </Grid>

                <Grid item xs={12} md={2} lg={2}>
                    {!roomData.ID
                        ?
                        <span> </span>
                        :

                        <div style={{ padding: '10px 10px', textAlign: 'right' }}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<LinkIcon />}
                                onClick={() => {
                                    setSelectedRoomsData(roomData);
                                    setOpenLinkUserDialog(true)
                                }}
                            >
                                {t('user')}
                            </Button>
                        </div>}

                </Grid>
            </Grid>


            <Grid container spacing={0}>

                <Grid item xs={12} md={12} lg={12}>
                    {!usersData.length
                        ?
                        <Paper className={fixedHeightSkeleton}>
                            <Skeleton animation="wave" width="100%" />
                            <Skeleton variant="circle" width={40} height={40} />
                            <Skeleton animation="wave" width="100%" />
                        </Paper>
                        :
                        <MaterialTable
                            style={{ boxShadow: "none" }}

                            columns={[
                                { title: t('id'), field: "id", cellStyle: { textAlign: 'center' } },
                                { title: t('login'), field: "login", cellStyle: { textAlign: 'center' } },
                                { title: t('name'), field: "name", cellStyle: { textAlign: 'center' } },
                                {
                                    title: t('is_manager'),
                                    field: "is_manager",
                                    cellStyle: { textAlign: 'center' },
                                    render: rowData => {
                                        return rowData.is_manager ? <VerifiedUserIcon color={"secondary"} /> :
                                            <BlockIcon />
                                    }
                                },
                            ]}
                            localization={tableLocalizations[i18n.language]}

                            data={usersData}
                            title={t('users')}
                            options={{
                                // actionsColumnIndex: -1,
                                actionsCellStyle: { color: 'red' },
                                header: true,
                                search: false,
                                showTitle: true,
                                pageSize: 5,
                                headerStyle: { textAlign: 'center' },
                                rowStyle: { textAlign: 'center' },
                                paginationType: 'stepped',
                                showPagination: false
                            }}
                            actions={[
                                {
                                    icon: 'link',
                                    tooltip: 'LinkOff User',
                                    onClick: (event, rowData) =>{
                                        setOpenUnlinkUserDialog(true);
                                        setSelectedUsersData(rowData)
                                    }
                                }
                            ]}

                        />

                    }
                </Grid>
            </Grid>


            <Grid container spacing={0}>

                <Grid item xs={12} md={12} lg={12}>
                    {!imeisData.length
                        ?
                        <Paper className={fixedHeightSkeleton}>
                            <Skeleton animation="wave" width="100%" />
                            <Skeleton variant="circle" width={40} height={40} />
                            <Skeleton animation="wave" width="100%" />
                        </Paper>
                        :
                        <MaterialTable
                            style={{ boxShadow: "none" }}

                            columns={[
                                //{ title: t('id'), field: "id", cellStyle: { textAlign: 'center' } },
                                { title: t('imei'), field: "imei", cellStyle: { textAlign: 'center' } },
                                { title: t('title'), field: "name", cellStyle: { textAlign: 'center' } },
                                { title: t('model'), field: "model", cellStyle: { textAlign: 'center' } },
                                { title: t('serial'), field: "serial", cellStyle: { textAlign: 'center' } },
                                { title: t('address'), field: "address", cellStyle: { textAlign: 'center' } },

                            ]}
                            localization={tableLocalizations[i18n.language]}

                            data={imeisData}
                            title={t('imeis')}
                            options={{
                                // actionsColumnIndex: -1,
                                actionsCellStyle: { color: 'red' },
                                header: true,
                                search: false,
                                showTitle: true,
                                pageSize: 5,
                                headerStyle: { textAlign: 'center' },
                                rowStyle: { textAlign: 'center' },
                                paginationType: 'stepped',
                                showPagination: false
                            }}
                            actions={[
                                {
                                    icon: 'link',
                                    tooltip: 'LinkOff IMEI',
                                    onClick: (event, rowData) =>{
                                        setOpenUnlinkImeiDialog(true);
                                        setSelectedImeisData(rowData)
                                    }
                                }
                            ]}

                        />

                    }
                </Grid>
                {
                    selectedRoomsData
                        ? <LinkUserDialog
                            isOpen={openLinkUserDialog}
                            closeDialog={handleCloseLinkDialog}
                            roomData={selectedRoomsData} />
                        : <span />
                }

{
                    selectedRoomsData
                        ? <LinkImeiDialog
                            isOpen={openLinkImeiDialog}
                            closeDialog={handleCloseLinkDialog}
                            roomData={selectedRoomsData} />
                        : <span />
                }

{
                selectedImeisData
                    ? <UnlinkImeiDialog
                        isOpen={openUnlinkImeiDialog}
                        closeDialog={handleCloseLinkDialog}
                        imei={selectedImeisData.imei} />
                    : <span/>
            }

{
                selectedUsersData
                    ? <UnlinkUserDialog
                        isOpen={openUnlinkUserDialog}
                        closeDialog={handleCloseLinkDialog}
                        user={selectedUsersData.id}
                        nuser={selectedUsersData.name}  />
                    : <span/>
            }
                
            </Grid>

        </React.Fragment>
    );
}


