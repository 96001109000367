import React from 'react'
import { Route } from 'react-router-dom'

import MainLayout from "./index";

const MainLayoutRoutes = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => {
            return (
                <MainLayout>
                    <Component {...props}/>
                </MainLayout>
            )
        }
        } />
    )
}

export default MainLayoutRoutes
