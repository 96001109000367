import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
//import Moment from "react-moment";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
    depositContext: {
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        textAlign: 'center'
    },
    fixedHeight: {
        height: 180,
    },
    fixedHeightSkeleton: {
        height: 180,
        justifyContent: 'center',
        alignItems: 'center'
    },
}));

export default function Counters(props) {
    const { t } = useTranslation()
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const fixedHeightSkeleton = clsx(classes.paper, classes.fixedHeightSkeleton);
    const [countersData] = useState(props.countersData)

    return (
        <React.Fragment>
            <Grid container spacing={3}>

                <Grid item xs={12} md={3} lg={3}>
                    {countersData === {}
                        ?
                        <Paper className={fixedHeightSkeleton}>
                            <Skeleton animation="wave" width="100%" />
                            <Skeleton variant="circle" width={40} height={40} />
                            <Skeleton animation="wave" width="100%" />
                        </Paper>
                        :
                        <Paper className={fixedHeightPaper}>
                            <Title>{t('counter')} COIN_IN</Title>
                            <Typography component="p" variant="h4" style={{ textAlign: 'center', margin: '16px' }}>
                                {countersData.cnt1}
                            </Typography>
                            {/* <Typography color="textSecondary" className={classes.depositContext}>
                                <Moment format="DD/MM/YYYY HH:mm:ss">
                                    {countersData.at}
                                </Moment>
                            </Typography> */}
                        </Paper>
                    }
                </Grid>

                <Grid item xs={12} md={3} lg={3}>
                    {countersData === {}
                        ?
                        <Paper className={fixedHeightSkeleton}>
                            <Skeleton animation="wave" width="100%" />
                            <Skeleton variant="circle" width={40} height={40} />
                            <Skeleton animation="wave" width="100%" />
                        </Paper>
                        :
                        <Paper className={fixedHeightPaper}>
                            <Title>{t('counter')} COIN_OUT</Title>
                            <Typography component="p" variant="h4" style={{ textAlign: 'center', margin: '16px' }}>
                                {countersData.cnt2}
                            </Typography>
                            {/*  <Typography color="textSecondary" className={classes.depositContext}>
                                <Moment format="DD/MM/YYYY HH:mm:ss">
                                    {countersData.at}
                                </Moment>
                            </Typography> */}
                        </Paper>
                    }
                </Grid>



                <Grid item xs={12} md={3} lg={3}>
                    {countersData === {}
                        ?
                        <Paper className={fixedHeightSkeleton}>
                            <Skeleton animation="wave" width="100%" />
                            <Skeleton variant="circle" width={40} height={40} />
                            <Skeleton animation="wave" width="100%" />
                        </Paper>
                        :
                        <Paper className={fixedHeightPaper}>
                            <Title>{t('counter')} KEY_IN</Title>
                            <Typography component="p" variant="h4" style={{ textAlign: 'center', margin: '16px' }}>
                                {countersData.cnt4}
                            </Typography>
                            {/* <Typography color="textSecondary" className={classes.depositContext}>
                                <Moment format="DD/MM/YYYY HH:mm:ss">
                                    {countersData.at}
                                </Moment>
                            </Typography> */}
                        </Paper>
                    }
                </Grid>

                <Grid item xs={12} md={3} lg={3}>
                    {countersData === {}
                        ?
                        <Paper className={fixedHeightSkeleton}>
                            <Skeleton animation="wave" width="100%" />
                            <Skeleton variant="circle" width={40} height={40} />
                            <Skeleton animation="wave" width="100%" />
                        </Paper>
                        :
                        <Paper className={fixedHeightPaper}>
                            <Title>{t('counter')} KEY_OUT</Title>
                            <Typography component="p" variant="h4" style={{ textAlign: 'center', margin: '16px' }}>
                                {countersData.cnt5}
                            </Typography>
                            {/*   <Typography color="textSecondary" className={classes.depositContext}>
                                <Moment format="DD/MM/YYYY HH:mm:ss">
                                    {countersData.at}
                                </Moment>
                            </Typography> */}
                        </Paper>
                    }
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    {countersData === {}
                        ?
                        <Paper className={fixedHeightSkeleton}>
                            <Skeleton animation="wave" width="100%" />
                            <Skeleton variant="circle" width={40} height={40} />
                            <Skeleton animation="wave" width="100%" />
                        </Paper>
                        :
                        <Paper className={fixedHeightPaper}>
                            <Title> {t('saldo')} </Title>
                            <Typography component="p" variant="h4" style={{ textAlign: 'center', margin: '16px' }}>
                                {countersData.saldo}
                            </Typography>
                            {/*  <Typography color="textSecondary" className={classes.depositContext}>
                                <Moment format="DD/MM/YYYY HH:mm:ss">
                                    {countersData.at}
                                </Moment>
                            </Typography> */}
                        </Paper>
                    }
                </Grid>
                {/* <Grid item xs={12} md={6} lg={6}>
                    {!countersData.id
                        ?
                        <Paper className={fixedHeightSkeleton}>
                            <Skeleton animation="wave" width="100%"/>
                            <Skeleton variant="circle" width={40} height={40}/>
                            <Skeleton animation="wave" width="100%"/>
                        </Paper>
                        :
                        <Paper className={fixedHeightPaper}>
                            <Title> {t('saldo')} </Title>
                            <Typography component="p" variant="h4" style={{textAlign: 'center', margin: '16px'}}>
                                {countersData.description}
                            </Typography>
                            <Typography color="textSecondary" className={classes.depositContext}>
                                <Moment format="DD/MM/YYYY HH:mm:ss">
                                    {countersData.at}
                                </Moment>
                            </Typography>
                        </Paper>
                    }
                </Grid>
 */}
            </Grid>
        </React.Fragment>
    );
}
