import React, { Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { useTranslation } from "react-i18next";
import Operator from "./Operator";

export default function OperatorIndex() {
    const { t } = useTranslation();

    return (
        <DocumentTitle title={'FISH-Andromeda || ' + t('mainmenu.dashboard')}>
            <Fragment>
                <Operator />
            </Fragment>
        </DocumentTitle>
    );
};
