import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import DashboardLayout from "./index";
import * as AuthService from "../../plugins/AuthService";


const DashboardLayoutRoutes = ({ component: Component, ...rest }) => {

    return (
        <Route {...rest} render={props =>

            AuthService.isAuthenticated() && !AuthService.isAuthenticated().adm && !AuthService.isAuthenticated().operator
                ?
                (
                    <DashboardLayout>
                        <Component {...props} />
                    </DashboardLayout>
                )
                : (
                    <Redirect
                        to={{
                            pathname: "/denied",
                            state: { from: props.location }
                        }}
                    />
                )


        } />
    )
}

export default DashboardLayoutRoutes
