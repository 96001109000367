import React, {useState, useEffect} from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Carousel from 'react-slick';
import clsx from 'clsx';
import imgAPI from '../../plugins/imgAPI';
import ImageThumbCard from '../Cards/ImageThumb';
import Title from '../Title';
import useStyle from './gallery-style';
import {useTranslation} from "react-i18next";

const portfolio = [
    {
        img: imgAPI.profile[4],
        title: 'Aenean facilisis vitae purus',
        link: 'fish-games.com.ua',
        size: 'short',
        category: 'cat1'
    },
    {
        img: imgAPI.profile[0],
        title: 'Aenean facilisis vitae purus',
        link: 'fish-games.com.ua',
        size: 'long',
        category: 'cat2'
    },
    {
        img: imgAPI.profile[5],
        title: 'Aenean facilisis vitae purus',
        link: 'fish-games.com.ua',
        size: 'short',
        category: 'cat3'
    },
    {
        img: imgAPI.profile[2],
        title: 'Aenean facilisis vitae purus',
        link: 'fish-games.com.ua',
        size: 'long',
        category: 'cat1'
    },
    {
        img: imgAPI.profile[6],
        title: 'Aenean facilisis vitae purus',
        link: 'fish-games.com.ua',
        size: 'short',
        category: 'cat2'
    },
    {
        img: imgAPI.profile[7],
        title: 'Aenean facilisis vitae purus',
        link: 'fish-games.com.ua',
        size: 'short',
        category: 'cat3'
    },
    {
        img: imgAPI.profile[8],
        title: 'Aenean facilisis vitae purus',
        link: 'fish-games.com.ua',
        size: 'short',
        category: 'cat1'
    },
    {
        img: imgAPI.profile[9],
        title: 'Aenean facilisis vitae purus',
        link: 'fish-games.com.ua',
        size: 'short',
        category: 'cat2'
    },
    {
        img: imgAPI.profile[1],
        title: 'Aenean facilisis vitae purus',
        link: 'fish-games.com.ua',
        size: 'long',
        category: 'cat2'
    },
];

export default function Gallery(props) {
    const classes = useStyle();
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [isLoaded, setLoaded] = useState(true);
    const [filter, setFilter] = useState('all');
    useEffect(() => {
        setData(portfolio);
    }, []);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        autoplay: false,
        slidesToShow: 1,
        arrows: false
    };

    const filterChildren = name => {
        if (name !== 'all') {
            const filteredData = portfolio.filter(item => item.category === name);
            setData(filteredData);
            setFilter(name);
        } else {
            setData(portfolio);
            setFilter('all');
        }

        setLoaded(false);
        setTimeout(() => {
            setLoaded(true);
        }, 700);
    };

    return (
        <div className={classes.root}>
            <Container>

                <Title>
                  {t('gallery_title')}
                  <strong>
                    {t('gallery_titleBold')}
                  </strong>
                </Title>


                <div className={classes.filter}>
                    <Button
                        onClick={() => filterChildren('all')}
                        className={filter === 'all' ? classes.selected : ''}
                    >
                        {t('all')}
                    </Button>
                    <Button
                        onClick={() => filterChildren('cat1')}
                        className={filter === 'cat1' ? classes.selected : ''}
                    >
                        {t('cat1')}
                    </Button>
                    <Button
                        onClick={() => filterChildren('cat2')}
                        className={filter === 'cat2' ? classes.selected : ''}
                    >
                        {t('cat2')}
                    </Button>
                    <Button
                        onClick={() => filterChildren('cat3')}
                        className={filter === 'cat3' ? classes.selected : ''}
                    >
                        {t('cat3')}
                    </Button>
                   {/* <Button
                        onClick={() => filterChildren('cat4')}
                        className={filter === 'cat4' ? classes.selected : ''}
                    >
                        Category 4
                    </Button>
                    <Button
                        onClick={() => filterChildren('cat5')}
                        className={filter === 'cat5' ? classes.selected : ''}
                    >
                        Category 5
                    </Button>*/}
                </div>
                <Hidden xsDown>
                    <div className={classes.massonry}>
                        {data.map((item, index) => (
                            <div
                                className={clsx(classes.item, isLoaded && classes.loaded)}
                                key={index.toString()}
                                style={{transitionDuration: index / 4 + 's'}}
                            >
                                <ImageThumbCard
                                    img={item.img}
                                    title={item.title}
                                    link={item.link}
                                    size={item.size}
                                />
                            </div>
                        ))}
                    </div>
                    {data.length < 1 && <Typography variant="button" component="p" align="center">{t('common:profile-landing.gallery_nodata')}</Typography>}
                </Hidden>
                <Hidden smUp>
                    <Carousel {...settings}>
                        {data.map((item, index) => (
                            <div
                                className={classes.itemCarousel}
                                key={index.toString()}
                            >
                                <ImageThumbCard
                                    img={item.img}
                                    title={item.title}
                                    link={item.link}
                                    size={item.size}
                                />
                            </div>
                        ))}
                    </Carousel>
                </Hidden>
            </Container>
        </div>
    );
}



